import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from "@heroicons/react/20/solid";
import { forwardRef } from "react";

import techimage from "../../../Assets/images/tech.png";

function Example() {
  return (
    <div className="relative px-6 py-24 overflow-hidden isolate sm:py-32 lg:overflow-visible lg:px-0" id='section-1'>
      <div className="absolute inset-0 overflow-hidden -z-10">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern id="e813992c-7d03-4cc4-a2bd-151760b470a0" width={200} height={200} x="50%" y={-1} patternUnits="userSpaceOnUse">
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-transparent">
            <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" strokeWidth={0} />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="grid max-w-2xl grid-cols-1 mx-auto gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-screen-2xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">About Us</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Xcode International</h1>
            </div>
          </div>
        </div>
        <div className="p-12 -mt-12 -ml-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img className="w-[23rem] max-w-none rounded-xl sm:w-[35rem]" src={techimage} alt="" />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-screen-2xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    At Xcode Software Company, we are committed to delivering cutting-edge software solutions that enable businesses to achieve their goals and meet their objectives.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                  Our team of skilled developers, designers, and project managers work closely with clients to understand their unique business needs and create tailored software solutions that address their specific requirements. With years of experience in the industry, we have successfully delivered a wide range of software solutions to clients across various industries. 
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                  Our expertise includes mobile app development, web app development, desktop app development, e-commerce solutions, and software testing services. 
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                  Our mission is to help businesses leverage the power of technology to increase efficiency, streamline operations, and drive growth. We believe in providing exceptional customer service and support, and we work tirelessly to ensure that our clients are satisfied with our work. 
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                  At Xcode Software Company, we pride ourselves on our commitment to quality, innovation, and excellence. Our team is dedicated to staying up-to-date with the latest technologies and trends in the industry to ensure that our clients receive the most advanced and effective software solutions.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(Example) 