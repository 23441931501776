import react from "react";
import { Canvas } from "@react-three/fiber";
import Blob from "../../Blob";
import Giorgi from "../../../Assets/images/giorgi.jpg";

export default function Example() {
  const handleClick = () => {
    const element = document.getElementById('section-2');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleClickTwo = () => {
    const element = document.getElementById('section-3');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="overflow-hidden py-5 sm:py-10">
      <div className="mx-auto max-w-screen-2xl px-6 lg:px-8">
        {/* Hero main container */}
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="mx-auto max-w-container px-4 pt-4 sm:px-6 lg:flex lg:px-8">
            {/* Hero main container first conteiner */}
            <div className="relative z-20 mx-auto max-w-[40rem] pb-16 pt-16 lg:mx-0 lg:w-[40rem] lg:max-w-none lg:flex-none lg:pb-24 lg:pr-4 lg:pt-20">
              <h1 className="text-base font-semibold leading-7 text-indigo-600">პროგრამირების კურსი</h1>
              <p className="mt-4 text-3xl font-bold tracking-[-0.04em] text-black sm:text-5xl sm:leading-[3.5rem]">Front-End დეველოპმენტი🚀</p>
              <div className="flex flex-wrap mt-4">
                <div className="flex items-center text-sm font-medium text-slate-500">
                  <svg
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 flex-none stroke-current text-slate-400"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M5 4.15h22.5l-2 20.5-9.25 4-9.25-4-2-20.5Z"></path>
                    <path d="M20.5 9.15H12v5.5h8.5v6l-4.25 2-4.25-2v-2.5"></path>
                  </svg>
                  <span className="ml-2.5">HTML</span>
                </div>
                <div className="flex items-center text-sm font-medium text-slate-500 ml-6">
                  <svg
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 flex-none stroke-current text-slate-400"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <ellipse cx="16" cy="16" rx="13" ry="5"></ellipse>
                    <ellipse cx="16" cy="16" rx="13" ry="5" transform="rotate(60 16 16)"></ellipse>
                    <ellipse rx="13" ry="5" transform="matrix(-.5 .86603 .86603 .5 16 16)"></ellipse>
                    <circle cx="16" cy="16" r="2"></circle>
                  </svg>
                  <span className="ml-2.5">React</span>
                </div>
              </div>
              <p className="mt-4 text-base leading-7 text-slate-600">
                კურსის ხანგრძლივობაა 7 თვე (56 სემინარი), კვირაში 2 ლექცია 2 საათის განმავლობაში. კურსის ყოველთვიური ღირებულება 400 ლარი. კურსზე
                მოსახვედრად საჭიროა ონლაინ რეგისტრაცია, რეგისტრაციის შემდეგ დამატებითი ინფორმაციისთვის დაგიკავშირდებათ ჩვენი გუნდის წევრი.
              </p>
              <div className="mt-8 flex gap-4">
                <button
                  className="inline-flex justify-center rounded-lg text-sm font-semibold py-3 px-4 bg-slate-900 text-white hover:bg-slate-700"
                  onClick={handleClick}
                >
                  <span>
                    გახდი კურსის წევრი{" "}
                    <span aria-hidden="true" className="text-slate-400 sm:inline">
                      {" "}
                      →{" "}
                    </span>
                  </span>
                </button>
                <button
                  className="inline-flex justify-center rounded-lg text-sm font-semibold py-3 px-4 bg-white/0 text-slate-900 ring-1 ring-slate-900/10 hover:bg-white/25 hover:ring-slate-900/15 "
                  onClick={handleClickTwo}
                >
                  <span>
                    გაიგეთ მეტი{" "}
                    <span aria-hidden="true" className="text-black/25 sm:inline">
                      {" "}
                      →{" "}
                    </span>
                  </span>
                </button>
              </div>
            </div>

            {/* Hero main container second conteiner */}
            <div className="relative z-10 mt-12 hidden select-none lg:flex w-[790px]">
              <div className="container">
                <Canvas camera={{ position: [0.0, 0.0, 8.0] }}>
                  <Blob />
                </Canvas>
              </div>

              <div className="relative p-4">
                <div className="absolute bottom-0 left-11 right-0 top-8 bg-slate-900/[0.03]"></div>
                <div className="z-0">
                  <div className="absolute -right-[95%] left-0 top-8 h-px bg-slate-900/[0.1] [mask-image:linear-gradient(to_right,transparent,white_4rem,white_calc(100%-4rem),transparent)]"></div>
                  <div className="absolute -bottom-16 -top-2 right-0 w-px bg-slate-900/[0.1] [mask-image:linear-gradient(to_top,transparent,white_4rem,white_calc(100%-4rem),transparent)]"></div>
                  <div className="absolute -right-2/3 top-px -mb-px flex h-8 items-end overflow-hidden">
                    <div className="flex -mb-px h-[2px] w-80 -scale-x-100">
                      <div className="w-full flex-none blur-sm [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                      <div className="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Hero last container */}
        <div className="relative z-20 sm:z-auto">
          <div className="mx-auto max-w-container px-4 pb-16 sm:px-6 lg:px-8">
            <div className="relative mx-auto grid max-w-[40rem] grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
              <div className="flex">
                <div className="p-0.5">
                  <svg
                    className="h-10 w-10 shrink-0 transform-cpu rounded-lg shadow-md shadow-indigo-500/[.12]"
                    viewBox="0 0 40 40"
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      d="M.75 8A7.25 7.25 0 018 .75h24A7.25 7.25 0 0139.25 8v24A7.25 7.25 0 0132 39.25H8A7.25 7.25 0 01.75 32V8z"
                      className="fill-indigo-50 stroke-indigo-500"
                    ></path>
                    <path
                      d="M27 25.335L30.25 27l-6.603 3.382a8 8 0 01-7.294 0L9.75 27 13 25.335m14-6.999L30.25 20l-6.603 3.382a8 8 0 01-7.294 0L9.75 20 13 18.336"
                      className="stroke-indigo-500/50"
                    ></path>
                    <path
                      d="M9.75 13l9.338-4.783a2 2 0 011.824 0L30.25 13l-6.603 3.382a8 8 0 01-7.294 0L9.75 13z"
                      className="stroke-indigo-500"
                    ></path>
                  </svg>
                </div>
                <div className="ml-6">
                  <h2 className="text-sm font-semibold leading-6 text-slate-900">HTML & CSS</h2>
                  <p className="mt-2 text-sm leading-6 text-slate-700">
                    ამ ლექციების განმავლობაში თქვენ შეისწავლით თუ როგორ მოაწყოთ თქვენი საკუთარი ვებსაიტის სტრუქტურა, კოდირება და გაშვება ნულიდან.
                    თქვენ ასევე შეისწავლით თუ როგორ ისარგებლოთ ვებ განვითარების თანამედროვე ინსტრუმენტებით.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="p-0.5">
                  <svg
                    className="h-10 w-10 shrink-0 transform-cpu rounded-lg shadow-md shadow-indigo-500/[.12]"
                    viewBox="0 0 40 40"
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      d="M.75 8A7.25 7.25 0 018 .75h24A7.25 7.25 0 0139.25 8v24A7.25 7.25 0 0132 39.25H8A7.25 7.25 0 01.75 32V8z"
                      className="fill-indigo-50 stroke-indigo-500"
                    ></path>
                    <path d="M22.25 11.75l-4.5 16.5" className="stroke-indigo-500/50"></path>
                    <path
                      d="M16.25 7.75h-.5a4 4 0 00-4 4v4.007a3 3 0 01-.88 2.122 3 3 0 000 4.242 3 3 0 01.88 2.122v4.007a4 4 0 004 4h.5m7.5-24.5h.5a4 4 0 014 4v4.007a3.002 3.002 0 00.878 2.122 3 3 0 010 4.242 3 3 0 00-.878 2.122v4.007a4 4 0 01-4 4h-.5"
                      className="stroke-indigo-500"
                    ></path>
                  </svg>
                </div>
                <div className="ml-6">
                  <h2 className="text-sm font-semibold leading-6 text-slate-900">მუშაობა Git-თან და GitHub-თან</h2>
                  <p className="mt-2 text-sm leading-6 text-slate-700">
                    პროფესიონალი დეველოპერები შეიძლება ბევრ საკითხზე არ შეთანხმდნენ, მაგრამ ისინი ყველა თანხმდებიან ერთ რამეზე: ვერსიის კონტროლი. ამ
                    კურსზე თქვენ შეისწავლით თუ როგორ გამოიყენოთ მსოფლიოში ყველაზე პოპულარული ვერსიების კონტროლის სისტემა, Git.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="p-0.5">
                  <svg
                    className="h-10 w-10 shrink-0 transform-cpu rounded-lg shadow-md shadow-indigo-500/[.12]"
                    viewBox="0 0 40 40"
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      d="M.75 8A7.25 7.25 0 018 .75h24A7.25 7.25 0 0139.25 8v24A7.25 7.25 0 0132 39.25H8A7.25 7.25 0 01.75 32V8z"
                      className="fill-indigo-50 stroke-indigo-500"
                    ></path>
                    <path
                      d="M7.75 21.25v-1.5a4 4 0 014-4h7.5m5 5v7.5a4 4 0 01-4 4h-1.5M27.75 32.25h.5a4 4 0 004-4v-10.5m-10-10h-10.5a4 4 0 00-4 4v.5"
                      className="stroke-indigo-500/50"
                    ></path>
                    <path d="M31.5 8.5l-23 23" className="stroke-indigo-500"></path>
                    <path d="M25.75 7.75h4.5a2 2 0 012 2v4.5m-18 18h-4.5a2 2 0 01-2-2v-4.5" className="stroke-indigo-500"></path>
                  </svg>
                </div>
                <div className="ml-6">
                  <h2 className="text-sm font-semibold leading-6 text-slate-900">რესფონსიული ვებსაიტის შექმნა</h2>
                  <p className="mt-2 text-sm leading-6 text-slate-700">
                    ამ კურსში თქვენ ასევე შეისწავლით როგორ დაწეროთ თქვენი კოდი ინდუსტრიის სტანდარტების მიხედვით, გამოიყენოთ მედია მოთხოვნები ეკრანის
                    სხვადასხვა ზომის დასაყენებლად, ასევე თუ როგორ გამოიყენოთ Flexbox-ი და გახადოთ თქვენი ვებ გვერდი რესფონსიული.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="p-0.5">
                  <svg
                    className="h-10 w-10 shrink-0 transform-cpu rounded-lg shadow-md shadow-indigo-500/[.12]"
                    viewBox="0 0 40 40"
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      d="M.75 8A7.25 7.25 0 018 .75h24A7.25 7.25 0 0139.25 8v24A7.25 7.25 0 0132 39.25H8A7.25 7.25 0 01.75 32V8z"
                      className="fill-indigo-50 stroke-indigo-500"
                    ></path>
                    <path d="M22.25 11.75l-4.5 16.5" className="stroke-indigo-500/50"></path>
                    <path
                      d="M16.25 7.75h-.5a4 4 0 00-4 4v4.007a3 3 0 01-.88 2.122 3 3 0 000 4.242 3 3 0 01.88 2.122v4.007a4 4 0 004 4h.5m7.5-24.5h.5a4 4 0 014 4v4.007a3.002 3.002 0 00.878 2.122 3 3 0 010 4.242 3 3 0 00-.878 2.122v4.007a4 4 0 01-4 4h-.5"
                      className="stroke-indigo-500"
                    ></path>
                  </svg>
                </div>
                <div className="ml-6">
                  <h2 className="text-sm font-semibold leading-6 text-slate-900">JavaScript-ის საფუძვლები</h2>
                  <p className="mt-2 text-sm leading-6 text-slate-700">
                    JavaScript-ის კურსის ფარგლებში შენ ისწავლი JavaScript პროგრამირების ენას, მისი მუშაობის პრინციპებს და სპეციფიკას.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="p-0.5">
                  <svg
                    className="h-10 w-10 shrink-0 transform-cpu rounded-lg shadow-md shadow-indigo-500/[.12]"
                    viewBox="0 0 40 40"
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      d="M.75 8A7.25 7.25 0 018 .75h24A7.25 7.25 0 0139.25 8v24A7.25 7.25 0 0132 39.25H8A7.25 7.25 0 01.75 32V8z"
                      className="fill-indigo-50 stroke-indigo-500"
                    ></path>
                    <path d="M22.25 11.75l-4.5 16.5" className="stroke-indigo-500/50"></path>
                    <path
                      d="M16.25 7.75h-.5a4 4 0 00-4 4v4.007a3 3 0 01-.88 2.122 3 3 0 000 4.242 3 3 0 01.88 2.122v4.007a4 4 0 004 4h.5m7.5-24.5h.5a4 4 0 014 4v4.007a3.002 3.002 0 00.878 2.122 3 3 0 010 4.242 3 3 0 00-.878 2.122v4.007a4 4 0 01-4 4h-.5"
                      className="stroke-indigo-500"
                    ></path>
                  </svg>
                </div>
                <div className="ml-6">
                  <h2 className="text-sm font-semibold leading-6 text-slate-900">React JavaScript</h2>
                  <p className="mt-2 text-sm leading-6 text-slate-700">
                    თქვენ განავითარებთ JavaScript-ის ძირითად უნარებს შემდეგ დონეზე და ისწავლით JavaScript-ის გამოყენებას React-თან ერთად, რათა შექმნათ
                    ელეგანტური, უაღრესად ინტერაქტიული ვებსაიტები.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
