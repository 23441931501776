
import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { db } from "../../../Firebase/firebase";
import { addDoc, collection } from "@firebase/firestore";
import { Switch } from '@headlessui/react'
import { Container } from "./styles";

export default function Example() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [textarea, setTextarea] = useState("");
  const [hideLightbox, setHideLightbox] = useState(false);
  const contactCollectionRef = collection(db, "contact");

  const handleContact = async (e:any) => {
    e.preventDefault();

    addDoc(contactCollectionRef, {
      name: name,
      email: email,
      text: text,
      textarea: textarea,
    })
      .then(() => {
        setHideLightbox(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="relative px-6 py-24 bg-white isolate sm:py-32 lg:px-8" id="section-2">
      <div className="max-w-2xl mx-auto text-center">
        <Container>
          <div className={`hidecourseform ${hideLightbox ? "courseformhide" : ""}`}>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Contact</h2>
          </div>
        </Container>
      </div>
      <form action="#" method="POST" className="max-w-xl mx-auto mt-16 sm:mt-20" onSubmit={handleContact}>
        <Container>
          <div className={`hidecourseform ${hideLightbox ? "courseformhide" : ""}`}>
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                  Name / Surname
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    value={name}
                    name="first-name"
                    id="first-name"
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="given-name"
                    required
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    id="email"
                    required
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                  Subject
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    name="text"
                    id="thema"
                    required
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    rows={4}
                    value={textarea}
                    onChange={(e) => setTextarea(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className="inline-flex justify-center w-full px-4 py-3 text-sm font-semibold text-white rounded-lg bg-slate-900 hover:bg-slate-700"
              >
                Send
              </button>
            </div>
          </div>

          <div className={`lightbox ${hideLightbox ? "hide-lightbox" : ""}`}>
            <p>🚀</p>
          </div>
        </Container>
      </form>
    </div>
  )
}