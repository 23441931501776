import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Home, Courses, Contact, Frontend} from "../Pages"

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />
        <Route
          path={"/home"}
          element={<Home />}
        />
        <Route
          path={"/courses"}
          element={<Courses />}
        />
         <Route
          path={"/contact"}
          element={<Contact />}
        />
        <Route
          path={"/courses/front-end-development"}
          element={<Frontend />}
        />
        
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
