import { CheckIcon } from "@heroicons/react/20/solid";

const includedFeatures = [
  "HTML & CSS",
  "მუშაობა Git-თან და GitHub-თან",
  "რესფონსიული ვებსაიტის შექმნა",
  "Javascript / Typescript ",
  "React JavaScript",
  "Chrome / Shell / GIT / NPM / AWS ",
];

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-screen-2xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Xcode აკადემიის კურსები</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
           გაეცანი შენთვის სასურველ კურსის დეტალებს და დარეგისტრირდი კურსზე რათა გახდე Xcode აკადემიის გუნდის წევრი🚀
          </p>
        </div>
        <div className="isolate relative overflow-hidden mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" aria-hidden="true">
            <div
              className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-10 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Front-End Development კურსი</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              კურსის ხანგრძლივობაა 7 თვე (56 სემინარი), კვირაში 2 ლექცია 2 საათის განმავლობაში. კურსის ყოველთვიური ღირებულება 400 ლარი. კურსზე
              მოსახვედრად საჭიროა ონლაინ რეგისტრაცია, რეგისტრაციის შემდეგ დამატებითი ინფორმაციისთვის დაგიკავშირდებათ ჩვენი გუნდის წევრი.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">კურსის დეტალები</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gradient-to-r from-violet-500 to-fuchsia-500 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 h-full">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-white">ძველი ფასი ₾3400</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-white">₾2800</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-white">GEL</span>
                </p>
                <a
                  href="/courses/front-end-development"
                  className="mt-10 block w-full rounded-md bg-white px-3 py-2 text-center text-sm font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  კურსის დეტალები
                </a>
                <p className="mt-6 text-xs leading-5 text-white">კურსზე რეგისტრაციას შეძლებთ კურსის დეტალების გაცნობის შემდეგ</p>
              </div>
            </div>
          </div>
        </div>
        <div className="isolate relative overflow-hidden mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" aria-hidden="true">
            <div
              className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#83a1ff] to-[#9089fc] opacity-10 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Cyber Security</h3>
            <p className="text-1xl mt-6 font-bold tracking-tight text-gray-900">კურსი მალე დაემატება</p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              კურსის ხანგრძლივობაა 7 თვე (60 სემინარი), კვირაში 2 ლექცია 2 საათის განმავლობაში. კურსის ყოველთვიური ღირებულება 428 ლარი. კურსზე
              მოსახვედრად საჭიროა ონლაინ რეგისტრაცია, რეგისტრაციის შემდეგ დამატებითი ინფორმაციისთვის დაგიკავშირდებათ ჩვენი გუნდის წევრი.
            </p>
            <p className="text-1xl mt-6 font-bold tracking-tight text-gray-900">ჩაეწერე მსურველთა სიაში</p>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gradient-to-r from-indigo-400 to-blue-300 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 h-full">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-white">ძველი ფასი ₾4000</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-white">₾3000</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-white">GEL</span>
                </p>
                <a
                  href="#"
                  className="mt-10 block w-full rounded-md bg-white px-3 py-2 text-center text-sm font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  კურსის დეტალები
                </a>
                <p className="mt-6 text-xs leading-5 text-white">კურსი მალე დაემატება</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
