import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Switch } from "@headlessui/react";
import { db } from "../../../Firebase/firebase";
import { addDoc, collection } from "@firebase/firestore";
import { Container } from "./styles";

export default function Example() {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [textarea, setTextarea] = useState("");
  const [hideLightbox, setHideLightbox] = useState(false);
  const contactCollectionRef = collection(db, "Become_course_member");

  const handleContact = async (e: any) => {
    e.preventDefault();

    addDoc(contactCollectionRef, {
      name: name,
      date: date,
      email: email,
      tel: tel,
      textarea: textarea,
    })
      .then(() => {
        setHideLightbox(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8" id="section-2">
      <div className="mx-auto max-w-2xl text-center">
        <Container>
          <div className={`hidecourseform ${hideLightbox ? "courseformhide" : ""}`}>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">გახდი კურსის წევრი</h2>
          </div>
        </Container>
      </div>
      <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20" onSubmit={handleContact}>
        <Container>
          <div className={`hidecourseform ${hideLightbox ? "courseformhide" : ""}`}>
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                  სახელი / გვარი
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    value={name}
                    name="first-name"
                    id="first-name"
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="given-name"
                    required
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                  დაბადების თარიღი
                </label>
                <div className="mt-2.5">
                  <input
                    type="date"
                    value={date}
                    name="contact-name"
                    placeholder="mm/dd/yyyy"
                    onChange={(e) => setDate(e.target.value)}
                    id="company"
                    required
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                  ელ-ფოსტა
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    id="email"
                    required
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                  ტელეფონი
                </label>
                <div className="mt-2.5">
                  <input
                    type="tel"
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                    name="phone-number"
                    id="phone-number"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                  მოგვიყევით თქვენი გამოცდილების შესახებ
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    rows={4}
                    value={textarea}
                    onChange={(e) => setTextarea(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className="inline-flex justify-center rounded-lg text-sm font-semibold py-3 px-4 w-full bg-slate-900 text-white hover:bg-slate-700"
              >
                რეგისტრაცია
              </button>
            </div>
          </div>

          <div className={`lightbox ${hideLightbox ? "hide-lightbox" : ""}`}>
            <p>თქვენ წარმატებით დარეგისტრირდით კურსზე, დამატებითი დეტალებისთვის დაგიკავშირდებით 🚀</p>
          </div>
        </Container>
      </form>
    </div>
  );
}
