import styled from "@emotion/styled";

export const Container = styled("div")`
  .lightbox {
    opacity: 0;
    z-index: -1;
    pointer-events: none;
  }

  .hidecourseform {
    opacity: 1;
    z-index: 99;
  }
  .courseformhide {
    display: none;
    z-index: -1;
    pointer-events: none;
  }

  .hide-lightbox {
    opacity: 1;
    z-index: 99;
  }
  .hide-lightbox p {
    font-size: 20px;
    padding-top: 10px;
    color: black;
    text-align: center;
  }
`;
