import React, { FC } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import { Hero } from "../../Components/CoursesComponents";
import Course from "../../Components/HomeComponents/Courses/Courses";
import CTA from "../../Components/HomeComponents/CTA/CTA";

export const Courses: FC = () => {
  return (
    <>
      <Header />
      <Course />
      <CTA />
      <Footer />
    </>
  );
};

export default Courses;
