import { FC } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Hero, AboutCourse, CourseForm } from "../../Components/FrontEndDevelopmentComponents";

export const Frontend: FC = () => {
  return (
    <>
      <div style={{ backgroundColor: "#f8fafc" }}>
        <div className="absolute inset-y-0 w-full bg-[url('/public/beams-components.png')] bg-[length:1000px_700px] bg-[position:calc(50%_+_190px)_-50px] bg-no-repeat lg:block"></div>
        <Header />
        <Hero />
      </div>
      <AboutCourse/>
      <CourseForm />
      <Footer/>
    </>
  );
};

export default Frontend;
