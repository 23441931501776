import { FC } from "react";
import Header from "../../Components/Header/Header";
import { Form } from "../../Components/ContactComponents"

export const  Contact: FC = () =>  {
  return (
    <>
    <Header />
    <Form />
    </>
  )
}

export default Contact