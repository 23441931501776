import react from "react";
import { Canvas } from "@react-three/fiber";
import Blob from "../../Blob";
import Giorgi from "../../../Assets/images/giorgi.jpg";

export default function Example() {

  const handleClick = () => {
    const element = document.getElementById('section-1');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="py-5 overflow-hidden sm:py-10">
      <div className="px-6 mx-auto max-w-screen-2xl lg:px-8">
        {/* Hero main container */}
        <div className="grid max-w-2xl grid-cols-1 mx-auto gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="px-4 pt-4 mx-auto max-w-container sm:px-6 lg:flex lg:px-8">
            {/* Hero main container first conteiner */}
            <div className="relative z-20 mx-auto max-w-[40rem] pb-16 pt-16 lg:mx-0 lg:w-[40rem] lg:max-w-none lg:flex-none lg:pb-24 lg:pr-4 lg:pt-20">
              <h1 className="text-base font-semibold leading-7 text-indigo-600">Xcode International</h1>
              <p className="mt-4 text-3xl font-bold tracking-[-0.04em] text-black sm:text-5xl sm:leading-[3.5rem]">
              Build or scale up your development team. In weeks, not months🚀
              </p>
              
              <p className="mt-4 text-base leading-7 text-slate-600">
              Building software for world changers, At Xcode Software Company, we are committed to delivering cutting-edge software solutions that enable businesses to achieve their goals and meet their objectives. Our team of skilled developers, designers, and project managers work closely with clients to understand their unique business needs and create tailored software solutions that address their specific requirements.
              </p>
              <div className="flex gap-4 mt-8">
                <a
                  className="inline-flex justify-center px-4 py-3 text-sm font-semibold text-white rounded-lg bg-slate-900 hover:bg-slate-700"
                  href="https://calendly.com/xcode_international/30min?back=1&month=2024-02"
                >
                  <span>
                  Book free consultation{" "}
                    <span aria-hidden="true" className="text-slate-400 sm:inline">
                      {" "}
                      →{" "}
                    </span>
                  </span>
                </a>
                <button
                  className="inline-flex justify-center px-4 py-3 text-sm font-semibold rounded-lg bg-white/0 text-slate-900 ring-1 ring-slate-900/10 hover:bg-white/25 hover:ring-slate-900/15 "
                  onClick={handleClick}
                >
                  <span>
                  Learn more{" "}
                    <span aria-hidden="true" className="text-black/25 sm:inline">
                      {" "}
                      →{" "}
                    </span>
                  </span>
                </button>
              </div>
            </div>

            {/* Hero main container second conteiner */}
            <div className="relative z-10 mt-12 hidden select-none lg:flex w-[790px]">
              <div className="container">
                <Canvas camera={{ position: [0.0, 0.0, 8.0] }}>
                  <Blob />
                </Canvas>
              </div>
              {/* <div className="absolute z-10 mt-8">
                <div className="relative p-4">
                  <div className="w-[24.5rem] divide-y divide-slate-400/20 rounded-lg bg-white text-[0.8125rem] leading-5 text-slate-900 shadow-xl shadow-black/5 ring-1 ring-slate-700/10">
                    <div className="flex items-center p-4">
                      <img src={Giorgi} alt="" className="flex-none w-10 h-10 rounded-full" />
                      <div className="flex-auto ml-4">
                        <div className="font-medium">Front-End Development-ის კურსი</div>
                        <div className="mt-1 text-slate-700">@giorgishonia</div>
                      </div>
                      <a
                        href="/courses/front-end-development"
                        className="pointer-events-auto ml-4 flex-none rounded-md px-2 py-[0.3125rem] font-medium text-slate-700 shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50"
                      >
                        ნახვა
                      </a>
                    </div>
                    <div className="flex items-center p-4">
                      <img src={Giorgi} alt="" className="flex-none w-10 h-10 rounded-full" />
                      <div className="flex-auto ml-4">
                        <div className="font-medium">Cyber Security კურსი</div>
                        <div className="mt-1 text-slate-700">@girogishonia</div>
                      </div>
                      <div className="pointer-events-auto ml-4 flex-none rounded-md px-2 py-[0.3125rem] font-medium text-slate-700 shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50">
                        ნახვა
                      </div>
                    </div>
                    <div className="p-4">
                      <a href="/courses">
                        <div className="px-4 py-2 font-medium text-center rounded-md shadow-sm pointer-events-auto ring-1 ring-slate-700/10 hover:bg-slate-50">
                          ყველას ნახვა
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="relative p-4">
                <div className="absolute bottom-0 left-11 right-0 top-8 bg-slate-900/[0.03]"></div>
                <div className="z-0">
                  <div className="absolute -right-[95%] left-0 top-8 h-px bg-slate-900/[0.1] [mask-image:linear-gradient(to_right,transparent,white_4rem,white_calc(100%-4rem),transparent)]"></div>
                  <div className="absolute -bottom-16 -top-2 right-0 w-px bg-slate-900/[0.1] [mask-image:linear-gradient(to_top,transparent,white_4rem,white_calc(100%-4rem),transparent)]"></div>
                  <div className="absolute flex items-end h-8 -mb-px overflow-hidden -right-2/3 top-px">
                    <div className="flex -mb-px h-[2px] w-80 -scale-x-100">
                      <div className="w-full flex-none blur-sm [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                      <div className="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Hero last container */}
        <div className="relative z-20 sm:z-auto">
          <div className="px-4 pb-16 mx-auto max-w-container sm:px-6 lg:px-8">
            <div className="relative mx-auto grid max-w-[40rem] grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
              <div className="flex">
                <div className="p-0.5">
                  <svg
                    className="h-10 w-10 shrink-0 transform-cpu rounded-lg shadow-md shadow-indigo-500/[.12]"
                    viewBox="0 0 40 40"
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      d="M.75 8A7.25 7.25 0 018 .75h24A7.25 7.25 0 0139.25 8v24A7.25 7.25 0 0132 39.25H8A7.25 7.25 0 01.75 32V8z"
                      className="fill-indigo-50 stroke-indigo-500"
                    ></path>
                    <path
                      d="M27 25.335L30.25 27l-6.603 3.382a8 8 0 01-7.294 0L9.75 27 13 25.335m14-6.999L30.25 20l-6.603 3.382a8 8 0 01-7.294 0L9.75 20 13 18.336"
                      className="stroke-indigo-500/50"
                    ></path>
                    <path
                      d="M9.75 13l9.338-4.783a2 2 0 011.824 0L30.25 13l-6.603 3.382a8 8 0 01-7.294 0L9.75 13z"
                      className="stroke-indigo-500"
                    ></path>
                  </svg>
                </div>
                <div className="ml-6">
                  <h2 className="text-sm font-semibold leading-6 text-slate-900">Enterprise Software Development</h2>
                  <p className="mt-2 text-sm leading-6 text-slate-700">Create complex enterprise software, ensure reliable software integration, modernise your legacy system.</p>
                </div>
              </div>
              <div className="flex">
                <div className="p-0.5">
                  
                  <svg
                    className="h-10 w-10 shrink-0 transform-cpu rounded-lg shadow-md shadow-indigo-500/[.12]"
                    viewBox="0 0 40 40"
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      d="M.75 8A7.25 7.25 0 018 .75h24A7.25 7.25 0 0139.25 8v24A7.25 7.25 0 0132 39.25H8A7.25 7.25 0 01.75 32V8z"
                      className="fill-indigo-50 stroke-indigo-500"
                    ></path>
                    <path
                      d="M7.75 21.25v-1.5a4 4 0 014-4h7.5m5 5v7.5a4 4 0 01-4 4h-1.5M27.75 32.25h.5a4 4 0 004-4v-10.5m-10-10h-10.5a4 4 0 00-4 4v.5"
                      className="stroke-indigo-500/50"
                    ></path>
                    <path d="M31.5 8.5l-23 23" className="stroke-indigo-500"></path>
                    <path d="M25.75 7.75h4.5a2 2 0 012 2v4.5m-18 18h-4.5a2 2 0 01-2-2v-4.5" className="stroke-indigo-500"></path>
                  </svg>
                </div>
                <div className="ml-6">
                  <h2 className="text-sm font-semibold leading-6 text-slate-900">UX / UI Design</h2>
                  <p className="mt-2 text-sm leading-6 text-slate-700">Build the product you need on time with an experienced team that uses a clear and effective design process.</p>
                </div>
              </div>
              <div className="flex">
                <div className="p-0.5">
                <svg
                    className="h-10 w-10 shrink-0 transform-cpu rounded-lg shadow-md shadow-indigo-500/[.12]"
                    viewBox="0 0 40 40"
                    fill="none"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      d="M.75 8A7.25 7.25 0 018 .75h24A7.25 7.25 0 0139.25 8v24A7.25 7.25 0 0132 39.25H8A7.25 7.25 0 01.75 32V8z"
                      className="fill-indigo-50 stroke-indigo-500"
                    ></path>
                    <path d="M22.25 11.75l-4.5 16.5" className="stroke-indigo-500/50"></path>
                    <path
                      d="M16.25 7.75h-.5a4 4 0 00-4 4v4.007a3 3 0 01-.88 2.122 3 3 0 000 4.242 3 3 0 01.88 2.122v4.007a4 4 0 004 4h.5m7.5-24.5h.5a4 4 0 014 4v4.007a3.002 3.002 0 00.878 2.122 3 3 0 010 4.242 3 3 0 00-.878 2.122v4.007a4 4 0 01-4 4h-.5"
                      className="stroke-indigo-500"
                    ></path>
                  </svg>
                </div>
                <div className="ml-6">
                  <h2 className="text-sm font-semibold leading-6 text-slate-900">IT Consulting</h2>
                  <p className="mt-2 text-sm leading-6 text-slate-700">Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
