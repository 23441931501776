const fragmentShader = `
uniform float u_intensity;
uniform float u_time;
varying vec2 vUv;
varying float vDisplacement;
void main() {
    float distort = 2.0 * vDisplacement * u_intensity * sin(vUv.y * 50.0 + u_time);
    vec3 color = vec3(abs(vUv - 1.3) * 0.35  * (2.3 - distort), 9.0);
    gl_FragColor = vec4(color, 1.0);
}
`;

export default fragmentShader;