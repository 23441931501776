import { useState, Fragment } from "react";
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon, CheckIcon } from "@heroicons/react/20/solid";
import { ClockIcon, CalendarIcon, UserGroupIcon, BanknotesIcon, AcademicCapIcon } from "@heroicons/react/24/outline";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

import techimage from "../../../Assets/images/tech.png";
import tbcimage from "../../../Assets/images/tbc.png";
import visaimage from "../../../Assets/images/visa.png";
import Giorgi from "../../../Assets/images/giorgi.jpg";

export default function Example() {
  const [open, setOpen] = useState(0);
  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };
  const handleClick = () => {
    const element = document.getElementById("section-2");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0" id="section-3">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern id="e813992c-7d03-4cc4-a2bd-151760b470a0" width={200} height={200} x="50%" y={-1} patternUnits="userSpaceOnUse">
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-transparent">
            <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" strokeWidth={0} />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-screen-2xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">კურსის დეტალები</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">სილაბუსი</h1>
            </div>
          </div>
        </div>
        <div className="p-2 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <div className="rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] md:max-w-xl md:flex-row">
            <div className="flex flex-col justify-start p-6">
              <h5 className="mb-2 text-xl font-bold text-gray-900"></h5>
              <div className="mb-4 flex">
                <div>
                  <AcademicCapIcon className="mt-1 h-12 w-12 flex-none text-indigo-600" />
                </div>
                <div className="ml-5">
                  <span className="block text-xl font-bold text-gray-900">კურსის ფასი/</span>
                  <span>
                    <span className="text-xl font-bold text-gray-900">2800</span>
                    <span className="font-medium text-xl align-top">₾&thinsp;</span>
                  </span>
                  <span className="font-medium">/ თვეში 234₾&thinsp;- დან</span>
                </div>
              </div>
              <p className="mb-4 text-base text-gray-900 flex gap-x-3">
                <ClockIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                <span>შეხვედრები: 56</span>
              </p>
              <p className="mb-4 text-base text-gray-900 flex gap-x-3">
                <CalendarIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                <span>ხანგრძლივობა: 7 თვე</span>
              </p>
              <p className="mb-4 text-base text-gray-900 flex gap-x-3">
                <UserGroupIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                <span>ადგილი: 20</span>
              </p>
              <p className="mb-4 text-base text-gray-900 flex gap-x-3">
                <CalendarIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                <span>შეხვერის დღეები: ორშაბათი / ხუთშაბათი</span>
              </p>
              <p className="mb-4 text-base text-gray-900 flex gap-x-3">
                <ClockIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                <span>შეხვერის დრო: 20:00</span>
              </p>
              <p className="mb-4 text-base text-gray-900 flex gap-x-3">
                <span>კურსის დაწყების თარიღია 1 მაისი</span>
              </p>
              <p className="mb-4 text-base text-gray-900 flex gap-x-3">
                <span>ლექტორი: გიორგი შონია</span>
              </p>
            </div>
            <div className="p-6 -mt-4">
              <button
                className="inline-flex justify-center rounded-lg text-sm font-semibold py-3 px-4 w-full bg-slate-900 text-white hover:bg-slate-700"
                onClick={handleClick}
              >
                <span>
                  გახდი კურსის წევრი{" "}
                  <span aria-hidden="true" className="text-slate-400 sm:inline">
                    {" "}
                    →{" "}
                  </span>
                </span>
              </button>
            </div>
            <ul className="mt-1 font-medium text-xl flex w-full px-6">
              <li className="flex mb-6 w-full">
                <div className="ml-3 w-full flex justify-between flex-wrap">
                  <div className="flex">
                    <BanknotesIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                    <p className="text-base text-gray-900 flex gap-x-3"> &nbsp;&nbsp;განვადება:&nbsp;&nbsp;</p>
                    <span className="text-white">
                      <img src={tbcimage} alt="" style={{ width: 80 }} />
                    </span>
                  </div>
                  <div>
                    <img src={visaimage} alt="" style={{ width: 80 }} />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="mt-8 md:max-w-xl md:flex-row">
            <div className="w-full rounded-lg bg-white text-[0.8125rem] leading-5 text-slate-900 shadow-xl shadow-black/5 ring-1 ring-slate-700/10">
              <div className="flex items-center p-4">
                <img src={Giorgi} alt="" className="h-10 w-10 flex-none rounded-full" />
                <div className="ml-4 flex-auto">
                  <div className="font-medium">ჩანიშნე შეხვედრა ლექტორთან</div>
                  <div className="mt-1 text-slate-700">@giorgishonia</div>
                </div>
                <a
                  href="https://calendly.com/xcode_international/30min?back=1&month=2023-05"
                  className="pointer-events-auto ml-4 flex-none rounded-md px-2 py-[0.3125rem] font-medium text-slate-700 shadow-sm ring-1 ring-slate-700/10 hover:bg-slate-50"
                >
                  შეხვედრის ჩანიშვნა
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-screen-2xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                კურსის განმავლობაში ვისწავლით პროგრამირებისა და ვებ ტექნოლოგიების საფუძვლებს. 7 თვის შემდეგ შენ შეძლებ ნებისმიერი სირთულის React
                აპლიკაციის შექმნას და მის AWS-ზე ან Azure-ზე განთავსებას. კურსის დასაწყისში პროფესიონალურ დონეზე გავივლით HTML და CSS ტექნოლოგიებს,
                ხოლო Javascript-ის საფუძვლების შესწავლისას, დიდ დროს დავუთმობთ ალგორითმებს და მონაცემთა სტრუქტურებს.
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    <strong className="font-semibold text-gray-900">რას ისწავლი</strong>
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CheckIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>როგორ ააწყო 5 სხვადასხვა პროექტი შენი პორტფოლიოსთვის</span>
                </li>
                <li className="flex gap-x-3">
                  <CheckIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>პროგრამირების, ალგორითმების, Web და React დეველოფმენტის საფუძვლებს</span>
                </li>
                <li className="flex gap-x-3">
                  <CheckIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>Git-თან და Jira-სთან მუშაობას</span>
                </li>
                <li className="flex gap-x-3">
                  <CheckIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>LInux, DNS, AWS, Azure და სხვა დამხმარე ინფრასტრუქტურასთან მუშაობას</span>
                </li>
              </ul>
            </div>
            <li className="flex gap-x-3 mt-6 leading-8">
              <p>
                <strong className="font-semibold text-gray-900">ლექციები</strong>
                <br></br>7 თვე, 56 ლექცია
              </p>
            </li>
            <Fragment>
              <div className="rounded-lg text-sm font-semibold p-5 px-4 bg-white/0 text-slate-900 ring-1 ring-slate-900/10 hover:bg-white/25 hover:ring-slate-900/15 mt-8">
                <Accordion open={open === 1} animate={customAnimation}>
                  <AccordionHeader onClick={() => handleOpen(1)} className="font-semibold text-gray-900 border-none">
                    HTML
                  </AccordionHeader>
                  <AccordionBody className="text-gray-600 leading-8 mt-3">
                    <li>What is the Internet and how does it work</li>
                    <li>What is WWW, servers and clients</li>
                    <li>Transport Control Protocol</li>
                    <li>Application protocols</li>
                    <li>What is HTML</li>
                    <li>HTML syntax</li>
                    <li>Block & Inline elements</li>
                    <li>Links, URLs and Paths</li>
                    <li>Embed: photos, audio-video, Iframe</li>
                    <li>Tables</li>
                    <li>Forms</li>
                    <li>Semantic tags and HTML validation</li>
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 2} animate={customAnimation} className="mt-8">
                  <AccordionHeader onClick={() => handleOpen(2)} className="font-semibold text-gray-900 border-none">
                    CSS
                  </AccordionHeader>
                  <AccordionBody className="text-gray-600 leading-8 mt-3">
                    <li>Introduction to CSS</li>
                    <li>What is CSS</li>
                    <li>Cascade, specificity, inheritance</li>
                    <li>Selectors, pseudo classes and elements, combinators</li>
                    <li>CSS Box model</li>
                    <li>CSS Flex box (Flexbox, Grids)</li>
                    <li>:hover, :active, :nth-child, :before, :after</li>
                    <li>Responsive Design</li>
                    <li>Adaptive design with flex and grid</li>
                    <li>Media queries</li>
                    <li>Transition and transform</li>
                    <li>Animations</li>
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 3} animate={customAnimation} className="mt-8">
                  <AccordionHeader onClick={() => handleOpen(3)} className="font-semibold text-gray-900 border-none">
                    Javascript / Typescript
                  </AccordionHeader>
                  <AccordionBody className="text-gray-600 leading-8 mt-3">
                    <li>Introduction to Javascript</li>
                    <li>Hello world!</li>
                    <li>Variables</li>
                    <li>Interaction: alert and console logs</li>
                    <li>Type conversions</li>
                    <li>Basic operations</li>
                    <li>JS random</li>
                    <li>Conditional operators: if and short hand ? :, switch case</li>
                    <li>Loops: while and for</li>
                    <li>What are functions and where are they used</li>
                    <li>Different types of functions(arrow functions)</li>
                    <li>Debugging in Chrome</li>
                    <li>Coding styles</li>
                    <li>Comments</li>
                    <li>Arrays</li>
                    <li>Array methods(map, filter, find, findIndex, indexOf)</li>
                    <li>Date and time in JS</li>
                    <li>setInterval() and setTimeout()</li>
                    <li>What is DOM?</li>
                    <li>DOM elements</li>
                    <li>DOM methods</li>
                    <li>DOM HTML element events</li>
                    <li>DOM event listeners</li>
                    <li>What is OOP?</li>
                    <li>Objects</li>
                    <li>Object methods</li>
                    <li>The “this” keyword</li>
                    <li>Accessing Object methods</li>
                    <li>Object copying, references</li>
                    <li>JS classes</li>
                    <li>What is a class</li>
                    <li>Static Methods</li>
                    <li>Inheritance</li>
                    <li>Extends and super() keywords</li>
                    <li>Getters and Setters</li>
                    <li>What is JSON</li>
                    <li>Use cases for JSON</li>
                    <li>JSON methods</li>
                    <li>Error handling try … catch</li>
                    <li>Custom errors, extending error</li>
                    <li>HTML rendering</li>
                    <li>What is an API</li>
                    <li>Request types(Get, post, put, delete)</li>
                    <li>What is fetch API request</li>
                    <li>How to make fetch API request</li>
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 4} animate={customAnimation} className="mt-8">
                  <AccordionHeader onClick={() => handleOpen(4)} className="font-semibold text-gray-900 border-none">
                    Algorithms
                  </AccordionHeader>
                  <AccordionBody className="text-gray-600 leading-8 mt-3">
                    <li>Introduction to Algorithms</li>
                    <li>Big O</li>
                    <li>Analysis of the problem</li>
                    <li>Problem solving patterns</li>
                    <li>Search</li>
                    <li>Sorting</li>
                    <li>Recursion</li>
                    <li>An overview of data structures</li>
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 5} animate={customAnimation} className="mt-8">
                  <AccordionHeader onClick={() => handleOpen(5)} className="font-semibold text-gray-900 border-none">
                    React / Redux / Material / Ant
                  </AccordionHeader>
                  <AccordionBody className="text-gray-600 leading-8 mt-3">
                    <li>Introduction to React</li>
                    <li>Component, State</li>
                    <li>JSX</li>
                    <li>Render patterns, Input binding</li>
                    <li>Component lifecycle, Effects</li>
                    <li>Hooks</li>
                    <li>Material UI</li>
                    <li>Ant Design</li>
                    <li>Component patterns</li>
                    <li>Routing</li>
                    <li>Form management</li>
                    <li>Redux</li>
                    <li>Project patterns</li>
                  </AccordionBody>
                </Accordion>
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
}
