
import { initializeApp } from "firebase/app";
import {getFirebase} from "@firebase/firestore"
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDwmpnCV1KWWP4AwyOLLw6tUlK8Kyrg4kY",
  authDomain: "xcode-b1476.firebaseapp.com",
  projectId: "xcode-b1476",
  storageBucket: "xcode-b1476.appspot.com",
  messagingSenderId: "628228046927",
  appId: "1:628228046927:web:cd54e407675bbcd856aa3a",
  measurementId: "G-JMWQECZSM4"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();
const firestore = initializeApp(firebaseConfig);
export { db, firestore }